import '../css/styles.css';
var $ = require( "jquery" );

$(document).on('submit','#contactForm',SubForm);
function SubForm(e){
    $('#contactError').addClass("hidden")
    e.preventDefault();
    var response = grecaptcha.getResponse();

    if(response.length == 0){
        //reCaptcha not verified
        $('#contactError').removeClass("hidden")
        return false;
    }else{
        //reCaptch verified
        document.getElementById("contactForm").action = "https://aaxmiv5cha.execute-api.us-west-2.amazonaws.com/default/mylodocscontact"
        var url=$(this).closest('form').attr('action'),
        data=$(this).closest('form').serialize();
        $.ajax({
            url:url,
            type:'post',
            data:data
        }).done(function(){
            $('#contactForm').trigger('reset')
            $('#contactSuccess').toggleClass("hidden")
        }).fail(function(){
            $('#contactError').removeClass("hidden")
        });
    }
      return true;
    }
